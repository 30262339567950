enum ActionTypes {
	GET_CONSULTS_WITH_ID = 'GET_CONSULTS_WITH_ID',
	GET_CONSULTS_REQUEST = 'GET_CONSULTS_REQUEST',
	SET_NEXT_STATUS = 'SET_NEXT_STATUS',
	SET_CONSULT_COUNT = 'SET_CONSULT_COUNT',
	FETCH_MORE_CONSULTS = 'FETCH_MORE_CONSULTS',
	MOD_CONSULTS_REQUEST = 'MOD_CONSULTS_REQUEST',
	CONSULTS_REQ_SUCCESS = 'CONSULTS_REQ_SUCCESS',
	CONSULT_REQ_NOTFOUND = 'CONSULT_REQ_NOTFOUND',
	CONSULTS_REQ_FAILURE = 'CONSULTS_REQ_FAILURE',

	PDF_UPLOAD_REQUEST = 'PDF_UPLOAD_REQUEST',
	PDF_UPLOAD_PROCESS = 'PDF_UPLOAD_PROCESS',
	SET_SCANNED_RESULT = 'SET_SCANNED_RESULT',
	UPLOAD_PDF_SUCCESS = 'UPLOAD_PDF_SUCCESS',
	UPLOAD_PDF_FAILURE = 'UPLOAD_PDF_FAILURE',

	SET_LOADING_STATUS = 'SET_LOADING_STATUS',
	SET_FETCHING_STATUS = 'SET_FETCHING_STATUS',
}

export default ActionTypes;
