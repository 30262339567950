import { ProgressBarComponent } from '@components';
import { useConsultAction, useSelector, useUserAction } from '@hooks';
import { Notify } from '@utils';
import React, { useEffect, useRef } from 'react';
import { FaPlusSquare, FaSpinner } from 'react-icons/fa';
import './_style.scss';

export const HeaderComponent: React.FC = () => {
	const {
		consult: { loading, message, uploading },
		user: { currentUser },
	} = useSelector((state) => state);

	const consultAction = useConsultAction();
	const userAction = useUserAction();

	const InputRef = useRef<HTMLInputElement>(null);

	const hour = new Date().getHours();
	const time = hour < 12 ? 'morning' : hour < 16 ? 'afternoon' : hour < 20 ? 'evening' : 'night';

	useEffect(() => {
		if (!uploading) return;

		window.onbeforeunload = (e) => {
			if (e) e.returnValue = 'Sure?';
			return 'Sure?';
		};

		return () => {
			window.onbeforeunload = null;
		};
	}, [uploading]);

	const onChangeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files ? e.target.files[0] : null;
		if (!file) return;

		e.target.value = '';

		if (file.size > 100 * 1024 * 1024) {
			return Notify('error', 'File size is too big. Please upload a file less than 100MB.', true);
		}

		if ('application/pdf' !== file.type) return alert('Invalid file type!');

		const newFile = new File([file], renamePdf(file.name), {
			type: 'application/pdf',
		});

		await consultAction.uploadAndProcessPDF(newFile);
	};

	const renamePdf = (value: string) => {
		return value
			.toLowerCase()
			.replaceAll(/[\s`~!@#$%^&*()\-=+[\]{}|;:'",.<>/?]/g, '_')
			.replaceAll(/_+/g, '_')
			.replace(/^_/, '')
			.replace(/(_pdf$|pdf$)/, '.pdf');
	};

	return (
		<header className={'header'}>
			<ProgressBarComponent active={loading} />

			<div className={'header-container'}>
				<div className={'header--logo'}>
					<a href={'/'} tabIndex={-1}>
						<img
							src={`https://alt-trans.com/wp-content/uploads/2020/06/Alternative_transportation_Systems_Logo.jpg`}
							alt={'ats-logo'}
						/>
						<span>TextMaker</span>
					</a>
				</div>

				<div className={'header__action'}>
					<input
						type={'file'}
						accept={'application/pdf'}
						name={'pdf'}
						style={{ display: 'none' }}
						ref={InputRef}
						onChange={onChangeHandler}
						required
					/>

					<button
						className={`header__action--upload ${uploading && 'spin'}`}
						onClick={() => InputRef.current?.click()}
						disabled={uploading}
					>
						{uploading ? (
							<>
								<FaSpinner />
								<span>{message || 'uploading . .'}</span>
							</>
						) : (
							<FaPlusSquare />
						)}
					</button>
				</div>

				<div className={'header__user'}>
					Good {time} {currentUser?.name} /{' '}
					<button
						onClick={async () => {
							if (!window.confirm('Are you sure want to logout?')) return;
							await userAction.removeUser();
						}}
					>
						logout?
					</button>
				</div>
			</div>
		</header>
	);
};
