import React from 'react';
import './_style.scss';

interface IProps {
	active: boolean;
}

export const ProgressBarComponent: React.FC<IProps> = ({ active }) => (
	<div className={`bar-loader ${active ? 'active' : ''}`}>
		<span /> <span />
	</div>
);
