import { InputBoxComponent } from '@components';
import { useConsultAction, useSelector } from '@hooks';
import {
	$,
	EStatusText,
	isValidAge,
	isValidConsult_Id,
	isValidPhone,
	isValidPostalCode,
	isValidSocialSecurityNo,
	Notify,
} from '@utils';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import './_style.scss';

interface IProps {
	setModified?: React.Dispatch<React.SetStateAction<boolean>>;
	data: IConsultData;
}

export const RMSFormComponent: React.FC<IProps> = ({ setModified, data }) => {
	const { error, loading } = useSelector((state) => state.consult);

	const consultAction = useConsultAction();

	const [ConsultData, setConsultData] = useState(data);

	const setWarning = useMemo(
		() => (name: string, message: string) => {
			$(name)[0]?.parentElement?.querySelector('div')?.setAttribute('data-message', message);
			$(name)[0]?.parentElement?.classList.add('warn');
		},
		[],
	);

	const delWarning = useMemo(
		() =>
			(...name: string[]) => {
				name.forEach((v) => {
					$(v)[0]?.parentElement?.querySelector('div')?.removeAttribute('data-message');
					$(v)[0]?.parentElement?.classList.remove('warn');
				});
			},
		[],
	);

	useEffect(() => setConsultData(data), [data]);

	useEffect(() => {
		if (!loading) $('button.proof').forEach((el) => el.classList.remove('progress'));

		if (error)
			return setConsultData((v) => ({
				...v,
				status: 'ERROR',
			}));

		if (!loading && setModified) setModified(false);
	}, [setModified, error, loading]);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (isValidConsult_Id(ConsultData.consult_id)) delWarning('#consult_id');
			else setWarning('#consult_id', 'Invalid consult id!');

			if (isValidSocialSecurityNo(ConsultData.social_security_no))
				delWarning('#social_security_no');
			else setWarning('#social_security_no', 'Invalid social security no!');

			if (isValidPostalCode(ConsultData.postal_code)) delWarning('#postal_code');
			else setWarning('#postal_code', 'Invalid postal code!');

			if (isValidAge(ConsultData.age)) delWarning('#age');
			else setWarning('#age', 'Age might not be valid!');

			if (isValidPhone(ConsultData.phone_1)) delWarning('#phone_1');
			else setWarning('#phone_1', 'Invalid phone number!');

			if (ConsultData.phone_2) {
				if (isValidPhone(ConsultData.phone_2)) delWarning('#phone_2');
				else setWarning('#phone_2', 'Invalid phone number!');
			} else {
				delWarning('#phone_2');
			}
		}, 5e2);

		return () => clearTimeout(timer);
	}, [
		delWarning,
		setWarning,
		ConsultData.consult_id,
		ConsultData.social_security_no,
		ConsultData.postal_code,
		ConsultData.age,
		ConsultData.phone_1,
		ConsultData.phone_2,
	]);

	const onChangeHandler = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null,
		opt?: IOptional,
	) => {
		if (opt) {
			const updated_records = { ...ConsultData, [opt.name]: opt.value };

			if ('date_of_birth' === opt.name) {
				updated_records.age = new Date().getFullYear() - moment(opt.value).get('years');
			}

			setConsultData(updated_records);
		} else {
			if (!e) return;
			const value =
				'checkbox' === e.target.type ? (e.target as HTMLInputElement).checked : e.target.value;
			const updated_records = { ...ConsultData, [e.target.name]: value };

			setConsultData(updated_records);
		}

		setModified && setModified(true);
	};

	const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const { consult_id, social_security_no, age, postal_code, phone_1, phone_2 } = ConsultData;

		if (
			!isValidConsult_Id(consult_id) ||
			!isValidSocialSecurityNo(social_security_no) ||
			!isValidPostalCode(postal_code) ||
			!isValidAge(age) ||
			!isValidPhone(phone_1)
		) {
			return Notify('warning', 'Please validate all the fields!');
		}

		if (phone_2 && !isValidPhone(phone_2)) {
			return Notify('warning', 'Please validate all the fields!');
		}

		$("button[type='submit']")[0]?.classList.add('progress');

		const _new = {
			...ConsultData,
			status: EStatusText.VERIFIED,
		};

		await consultAction.modConsults(_new);
	};

	const onRejectHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
		if (!window.confirm('Are you sure you want to reject this consult?')) return;

		e.currentTarget.classList.add('progress');

		const _new = {
			...ConsultData,
			status: EStatusText.REJECTED,
		};

		return await consultAction.modConsults(_new);
	};

	const shrinkFields = [
		'consult_id',
		'social_security_no',
		'first_name',
		'last_name',
		'date_of_birth',
		'age',
		'phone_1',
		'phone_2',
		'appointment_date',
		'appointment_time',
		'one_trip_only',
		'round_trip',
		'patient_escorted',
		'special_needs',
		'eligible_for_travel',
		'diagnosis',
	];

	const typeCheck = (v: string) =>
		'date_of_birth' === v
			? 'date'
			: 'age' === v
			? 'number'
			: 'address' === v
			? 'textfield'
			: 'appointment_date' === v
			? 'date'
			: 'appointment_time' === v
			? 'time'
			: 'reason_for_request' === v
			? 'textfield'
			: 'one_trip_only' === v
			? 'checkbox'
			: 'round_trip' === v
			? 'checkbox'
			: 'comments' === v
			? 'textfield'
			: 'text';

	return (
		<div className={'rmsform'}>
			<form onSubmit={onSubmitHandler}>
				{Object.keys(data)
					.slice(2, -3)
					.map((v) => (
						<InputBoxComponent
							key={v + '_input'}
							onChange={onChangeHandler}
							checked={('one_trip_only' === v || 'round_trip' === v) && ConsultData[v]}
							name={v}
							shrink={shrinkFields.includes(v)}
							required={'phone_2' !== v && 'one_trip_only' !== v && 'round_trip' !== v}
							type={typeCheck(v)}
							// @ts-expect-error
							value={ConsultData[v]}
						/>
					))}

				<div className={'action'}>
					<div>
						<button type={'submit'} id={'confirm'} className={'proof'} disabled={loading}>
							<span>Confirm</span>
							<FaSpinner />
						</button>

						<button
							onClick={onRejectHandler}
							type={'button'}
							id={'reject'}
							className={'proof'}
							disabled={loading}
						>
							<span>Reject</span> <FaSpinner />
						</button>
					</div>

					<span className={`consult_status ${ConsultData.status.toUpperCase()}`}>
						{ConsultData.status.toUpperCase()}
					</span>
				</div>
			</form>
		</div>
	);
};
