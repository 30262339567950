import ActionTypes from '../../action-types/consult/consult.action-types';
import { ConsultAction as Action } from '../../actions';

interface IState {
	count: number;
	data: IConsultData[];
	error: string | null;
	fetching: boolean;
	loading: boolean;
	message: string | null;
	next: string;
	uploading: boolean;
}

const _init: IState = {
	count: 0,
	data: [],
	error: null,
	fetching: false,
	loading: false,
	message: null,
	next: '',
	uploading: false,
};

const reducer = (state = _init, action: Action): IState => {
	switch (action.type) {
		case ActionTypes.GET_CONSULTS_WITH_ID:
			return {
				...state,
				data: action.payload,
				error: null,
				loading: false,
			};
		case ActionTypes.GET_CONSULTS_REQUEST:
			return {
				...state,
				data: [],
				error: null,
				loading: true,
			};
		case ActionTypes.SET_NEXT_STATUS:
			return {
				...state,
				next: action.payload,
			};
		case ActionTypes.SET_CONSULT_COUNT:
			return {
				...state,
				count: action.payload,
			};
		case ActionTypes.FETCH_MORE_CONSULTS:
			return {
				...state,
				fetching: true,
				loading: true,
			};
		case ActionTypes.MOD_CONSULTS_REQUEST:
			return {
				...state,
				error: null,
				loading: true,
			};
		case ActionTypes.CONSULTS_REQ_SUCCESS:
			return {
				...state,
				data: action.payload,
				error: null,
				fetching: false,
				loading: false,
			};
		case ActionTypes.CONSULT_REQ_NOTFOUND:
			return {
				...state,
				data: [],
				loading: false,
			};
		case ActionTypes.CONSULTS_REQ_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case ActionTypes.PDF_UPLOAD_REQUEST:
			return {
				...state,
				message: null,
				uploading: true,
			};
		case ActionTypes.PDF_UPLOAD_PROCESS:
			return {
				...state,
				message: action.payload,
			};
		case ActionTypes.SET_SCANNED_RESULT:
			return {
				...state,
				data: action.payload,
			};
		case ActionTypes.UPLOAD_PDF_SUCCESS:
			return {
				...state,
				message: null,
				uploading: false,
			};
		case ActionTypes.UPLOAD_PDF_FAILURE:
			return {
				...state,
				message: null,
				uploading: false,
			};
		case ActionTypes.SET_LOADING_STATUS:
			return {
				...state,
				loading: action.payload,
			};
		case ActionTypes.SET_FETCHING_STATUS:
			return {
				...state,
				fetching: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
