import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import appReducer from './app/app.reducer';
import consultReducer from './consult/consult.reducer';
import userReducer from './user/user.reducer';

const config = { key: 'root', storage, whitelist: ['app', 'user'] },
	rootReducer = combineReducers({
		app: appReducer,
		consult: consultReducer,
		user: userReducer,
	});

export default persistReducer(config, rootReducer);
