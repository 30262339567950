import { ESortBy, ESortOrder } from '@utils';
import { Dispatch } from 'redux';
import { AppActionTypes as ActionTypes } from '../../action-types';
import { AppAction as Action } from '../../actions';

/**
 * `setFilterStatus` is a function that takes a string and returns an action
 * @param {string} status - string
 * @returns A function that dispatches an action.
 */

export const setFilterStatus = (status: string) => {
	return (dispatch: Dispatch<Action>) =>
		dispatch({
			type: ActionTypes.SET_FILTER_STATUS,
			payload: status,
		});
};

/**
 * `setActiveConsult` is a function that takes a number and returns an action
 * @param {number | null} id - number | null
 * @returns An action object.
 */
export const setActiveConsult = (id: number | null) => {
	return (dispatch: Dispatch<Action>) =>
		dispatch({
			type: ActionTypes.SET_ACTIVE_CONSULT,
			payload: id,
		});
};

/**
 * It dispatches an action to the store.
 * @param {ESortBy} sortBy - The sortBy value to set.
 * @returns A function that dispatches an action.
 */
export const setSortBy = (sortBy: ESortBy) => {
	return (dispatch: Dispatch<Action>) =>
		dispatch({
			type: ActionTypes.SET_SORT_BY,
			payload: sortBy,
		});
};

/**
 * It dispatches an action to the store.
 * @param {ESortOrder} sortOrder - The sort order to set.
 * @returns An action object.
 */
export const setSortOrder = (sortOrder: ESortOrder) => {
	return (dispatch: Dispatch<Action>) =>
		dispatch({
			type: ActionTypes.SET_SORT_ORDER,
			payload: sortOrder,
		});
};
