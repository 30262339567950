enum ActionTypes {
	SET_FILTER_STATUS = 'SET_FILTER_STATUS',
	SET_ACTIVE_CONSULT = 'SET_ACTIVE_CONSULT',
	RESET_APP_CONFIGS = 'RESET_APP_CONFIGS',

	SET_SORT_BY = 'SET_SORT_BY',
	SET_SORT_ORDER = 'SET_SORT_ORDER',
}

export default ActionTypes;
