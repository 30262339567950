import _axios from '@axios';
import { useStorage } from '@hooks';
import { store } from '@states';
import {
	$,
	capitalize,
	convertDateFormatToISO,
	convertDateFormatToRFC,
	ESortBy,
	EStatusText,
	httpToHttps,
	InitialConsult,
	Notify,
	sleep,
	unique,
} from '@utils';
import axios, { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import ActionTypes from '../../action-types/consult/consult.action-types';
import { ConsultAction as Action } from '../../actions';

/**
 * It gets the consults from the server and dispatches the appropriate action
 * @param {string} status - string - This is the status of the consults you want to get.
 * @returns An object with a type and a payload.
 */
export const getConsults = (status: string) => {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({ type: ActionTypes.GET_CONSULTS_REQUEST });

		const { sortBy, sortOrder } = store.getState().app;

		try {
			const {
				data: { count, next, results },
			} = (await _axios.get(
				`/api/rider_records/?status=${status}${
					ESortBy.DATE === sortBy
						? `&appointment_date=${sortOrder}`
						: ESortBy.ID === sortBy
						? `&sorted_by_id=${sortOrder}`
						: ESortBy.NAME === sortBy
						? `&sorted_by_lname=${sortOrder}`
						: ''
				}&offset=0&limit=5`,
			)) as {
				data: {
					count: number;
					next: string | null;
					results: IConsultData[];
				};
			};

			try {
				if (results[0].id) {
					dispatch({
						type: ActionTypes.CONSULTS_REQ_SUCCESS,
						payload: unique(results).map((v) =>
							convertDateFormatToRFC({
								...InitialConsult,
								...v,
							}),
						),
					});

					dispatch({
						type: ActionTypes.SET_CONSULT_COUNT,
						payload: count,
					});

					dispatch({
						type: ActionTypes.SET_NEXT_STATUS,
						payload: httpToHttps(next),
					});
				}
			} catch (e) {
				dispatch({ type: ActionTypes.SET_CONSULT_COUNT, payload: 0 });
				dispatch({ type: ActionTypes.CONSULT_REQ_NOTFOUND });

				Notify('info', 'No consult found!');
			}
		} catch (e) {
			const err = e as AxiosError;

			if (401 === err.response?.status) {
				alert('Session Expired! Please login again.');
				useStorage.clear();
				return window.location.reload();
			}

			dispatch({ type: ActionTypes.SET_CONSULT_COUNT, payload: 0 });
			dispatch({
				type: ActionTypes.CONSULTS_REQ_FAILURE,
				payload: err.response?.data,
			});

			Notify('error', 'Unexpected error occurred!', true);
		}
	};
};

/**
 * It fetches consults from the server and dispatches the result to the reducer
 * @param {string} value - string - The value to search for.
 * @param {boolean} isConsultId - boolean - This is to determine if the search is by consult id or rider name.
 * @param {EStatusText} [status] - The status of the consults to be fetched.
 * @param {boolean} [global] - boolean - This is used to determine if the search is global or not.
 * @returns An object with a type and a payload.
 */
export const searchConsults = (
	value: string,
	isConsultId: boolean,
	status?: EStatusText,
	global?: boolean,
) => {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({ type: ActionTypes.GET_CONSULTS_REQUEST });

		try {
			const _status = global ? 'all' : status || store.getState().app.filterStatus;

			const {
				data: { count, next, results },
			} = (await _axios.get(
				`/api/rider_records/?status=${_status}&${
					isConsultId ? 'consult_id' : 'rider_name'
				}=${value}&offset=0&limit=5`,
			)) as {
				data: {
					count: number;
					next: string | null;
					results: IConsultData[];
				};
			};

			try {
				if (results[0].id) {
					dispatch({
						type: ActionTypes.CONSULTS_REQ_SUCCESS,
						payload: unique(results).map((v) =>
							convertDateFormatToRFC({
								...InitialConsult,
								...v,
							}),
						),
					});

					dispatch({
						type: ActionTypes.SET_CONSULT_COUNT,
						payload: count,
					});

					dispatch({
						type: ActionTypes.SET_NEXT_STATUS,
						payload: httpToHttps(next),
					});
				}
			} catch (e) {
				dispatch({ type: ActionTypes.SET_CONSULT_COUNT, payload: 0 });
				dispatch({ type: ActionTypes.CONSULT_REQ_NOTFOUND });

				Notify('info', 'No consult found!');
			}
		} catch (e) {
			const err = e as AxiosError;

			if (401 === err.response?.status) {
				alert('Session Expired! Please login again.');
				useStorage.clear();
				return window.location.reload();
			}

			dispatch({ type: ActionTypes.SET_CONSULT_COUNT, payload: 0 });
			dispatch({
				type: ActionTypes.CONSULTS_REQ_FAILURE,
				payload: err.response?.data,
			});

			console.log(err.response);

			Notify('error', 'Unexpected error occurred!', true);
		}
	};
};

/**
 * This function takes a number and dispatches an action with that number as the payload.
 * @param {number} count - number - this is the number of consults that we want to set the state to.
 * @returns An object with a type and a payload.
 */
export const setConsultCount = (count: number) => {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionTypes.SET_CONSULT_COUNT,
			payload: count,
		});
	};
};

/**
 * It fetches more consults from the server and appends them to the existing consults in the store
 * @returns An object with a type and a payload.
 */
export const fetchMoreConsults = () => {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({ type: ActionTypes.FETCH_MORE_CONSULTS });

		try {
			const nextInStore = store.getState().consult.next;

			if (!nextInStore)
				return dispatch({
					type: ActionTypes.CONSULTS_REQ_SUCCESS,
					payload: store.getState().consult.data,
				});

			const {
				data: { next, results },
			} = (await _axios.get(nextInStore)) as {
				data: {
					next: string | null;
					results: IConsultData[];
				};
			};

			dispatch({
				type: ActionTypes.CONSULTS_REQ_SUCCESS,
				payload: unique([
					...store.getState().consult.data,
					...results.map((v) =>
						convertDateFormatToRFC({
							...InitialConsult,
							...v,
						}),
					),
				]),
			});

			dispatch({
				type: ActionTypes.SET_NEXT_STATUS,
				payload: httpToHttps(next),
			});
		} catch (e) {
			const err = e as AxiosError;

			if (401 === err.response?.status) {
				alert('Session Expired! Please login again.');
				useStorage.clear();
				return window.location.reload();
			}

			dispatch({
				type: ActionTypes.CONSULTS_REQ_FAILURE,
				payload: err.response?.data,
			});

			Notify('error', 'Unexpected error occurred!', true);
		}
	};
};

/**
 * It takes in a consult object, converts the date format to ISO, sends a PUT request to the server,
 * and then dispatches the response to the reducer
 * @param {IConsultData} consult - IConsultData - this is the consult data that we want to modify.
 * @returns An object with a type and a payload.
 */
export const modConsults = (consult: IConsultData) => {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({ type: ActionTypes.MOD_CONSULTS_REQUEST });

		try {
			const { data } = (await _axios.put(
				`/api/rider_records/${consult.id}/`,
				convertDateFormatToISO(consult),
			)) as {
				data: IConsultData;
			};

			const newRecords = store.getState().consult.data.map((v) =>
				consult.id === v.id
					? convertDateFormatToRFC({
							...InitialConsult,
							...data,
					  })
					: v,
			);

			dispatch({
				type: ActionTypes.CONSULTS_REQ_SUCCESS,
				payload: newRecords,
			});

			Notify('success', `${capitalize(consult.status)} consult successfully`);
		} catch (e) {
			const err = e as AxiosError;

			if (401 === err.response?.status) {
				alert('Session Expired! Please login again.');
				useStorage.clear();
				return window.location.reload();
			}

			dispatch({
				type: ActionTypes.CONSULTS_REQ_FAILURE,
				payload: err.response?.data || 'Network error!',
			});

			Notify('error', 'Unexpected error occurred!', true);
		}
	};
};

/**
 * It uploads a PDF file to the server, then it checks the status of the PDF file processing, then it
 * downloads the processed PDF file, then it processes the PDF file and saves the result to the
 * database
 * @param {File} file - File - The file object that is being uploaded.
 * @returns An object with a type and a payload.
 */
export const uploadAndProcessPDF = (file: File) => {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({ type: ActionTypes.PDF_UPLOAD_REQUEST });

		const formData = new FormData();

		try {
			formData.append('pdf', file);

			const axios_config = { headers: { 'Content-Type': 'multipart/form-data' } };
			const { data } = (await _axios.post('/api/requested_pdf/', formData, axios_config)) as {
				data: {
					status_code: number;
					statusQueryGetUri: string;
					message: string;
				};
			};

			if (500 === data.status_code) {
				dispatch({ type: ActionTypes.UPLOAD_PDF_FAILURE });

				return Notify('error', 'Error uploading the PDF!', true);
			}

			if (406 === data.status_code) {
				return Notify('info', 'Previous process is being cleaned!');
			}

			Notify('info', 'PDF splitting is being processed!');

			try {
				while (true) {
					await sleep(Math.floor(Math.random() * (12e4 - 6e4 + 1) + 6e4));

					const {
						data: { runtimeStatus },
					} = (await axios.get(data.statusQueryGetUri)) as { data: { runtimeStatus: string } };

					if ('Failed' === runtimeStatus) {
						dispatch({ type: ActionTypes.UPLOAD_PDF_FAILURE });

						return Notify('error', 'Error occurred while splitting the PDF!', true);
					}

					if ('Completed' === runtimeStatus) {
						try {
							dispatch({
								type: ActionTypes.PDF_UPLOAD_PROCESS,
								payload: 'downloading . .',
							});

							Notify('info', 'Downloading the pdf file');

							const { data } = (await _axios.post('/api/download/')) as {
								data: { status_code: number; message: string };
							};

							if (200 === data.status_code) Notify('success', data.message);
						} catch (e) {
							dispatch({ type: ActionTypes.UPLOAD_PDF_FAILURE });

							Notify('error', 'Error downloading images!', true);
						}

						dispatch({
							type: ActionTypes.PDF_UPLOAD_PROCESS,
							payload: 'processing . .',
						});

						Notify('info', 'Processing the pdf file');

						break;
					}
				}

				try {
					while (true) {
						const { data } = (await _axios.post('/api/rider_records/')) as {
							data: IResponse;
						};

						if (404 === data.status_code) {
							dispatch({ type: ActionTypes.UPLOAD_PDF_SUCCESS });

							const axios_config = { headers: { 'Content-Type': 'multipart/form-data' } };
							await _axios.post('/api/pdf/save', formData, axios_config);

							return Notify('success', 'Processing completed!');
						}

						if (501 === data.status_code) {
							Notify('error', data.message as string, true);

							continue;
						}

						if (400 === data.status_code)
							Notify('info', `Found duplicated consult ${(data.message as string[]).join(', ')}`);

						if (200 === data.status_code) {
							let scanned_consult = '';

							data.data.forEach((v) => (scanned_consult += v.consult_id + ', '));

							Notify(
								'info',
								`Scanned consult ${scanned_consult.substring(0, scanned_consult.length - 2)}`,
							);
						}

						if (EStatusText.PENDING === store.getState().app.filterStatus) {
							dispatch({
								type: ActionTypes.SET_SCANNED_RESULT,
								payload: [
									...data.data.map((v) =>
										convertDateFormatToRFC({
											...InitialConsult,
											...v,
										}),
									),
									...store.getState().consult.data,
								],
							});

							dispatch({
								type: ActionTypes.SET_CONSULT_COUNT,
								payload: store.getState().consult.count + data.data.length,
							});

							$('.sidebar__result')[0]?.scrollTo(0, 0);
						}
					}
				} catch {
					dispatch({ type: ActionTypes.UPLOAD_PDF_FAILURE });

					Notify('error', 'Error occurred while processing!', true);
				}
			} catch (e) {
				dispatch({ type: ActionTypes.UPLOAD_PDF_FAILURE });

				console.log(e);

				Notify('error', 'Error getting runtime status!', true);
			}
		} catch (e) {
			const err = e as AxiosError;

			if (401 === err.response?.status) {
				alert('Session Expired! Please login again.');
				useStorage.clear();
				return window.location.reload();
			}

			dispatch({ type: ActionTypes.UPLOAD_PDF_FAILURE });

			Notify('error', 'Unexpected error occurred!', true);
		}
	};
};

/**
 * It takes a boolean value as an argument, and returns a function that takes a dispatch function as an
 * argument, and dispatches an action with the type `SET_LOADING_STATUS` and the payload of the boolean
 * value that was passed in
 * @param {boolean} flag - boolean - This is the value that we want to set the loading status to.
 * @returns An object with a type and a payload.
 */
export const setLoadingStatus = (flag: boolean) => {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionTypes.SET_LOADING_STATUS,
			payload: flag,
		});
	};
};

/**
 * It takes a boolean value as an argument, and returns a function that takes a dispatch function as an
 * argument, and dispatches an action with the type `SET_FETCHING_STATUS` and the payload of the
 * boolean value that was passed in
 * @param {boolean} flag - boolean - this is the value that we want to set the fetching status to.
 * @returns An action object with a type and a payload.
 */
export const setFetchingStatus = (flag: boolean) => {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionTypes.SET_FETCHING_STATUS,
			payload: flag,
		});
	};
};
