import { PreviewComponent, RMSFormComponent, SidePanelComponent } from '@components';
import { useSelector } from '@hooks';
import { InitialConsult } from '@utils';
import React, { useEffect, useRef, useState } from 'react';
import './_style.scss';

export const MainPageComponent: React.FC = () => {
	const {
		app: { activeId },
		consult: { data },
	} = useSelector((state) => state);

	const MainPageRef = useRef<HTMLDivElement>(null);

	const [ActiveConsult, setActiveConsult] = useState(InitialConsult);
	const [IsModified, setModified] = useState(false);
	const [IsSidePanelActive, setSidePanelActive] = useState(true);

	useEffect(() => {
		setActiveConsult({
			...InitialConsult,
			...data.find((v) => activeId === v.id),
		});
	}, [data, activeId]);

	return (
		<div className={`main-page ${IsSidePanelActive && 'sidebar-active'}`} ref={MainPageRef}>
			<div className={'main-page__sidebar'}>
				<SidePanelComponent
					setModified={setModified}
					setSidePanelActive={setSidePanelActive}
					isModified={IsModified}
					isSidePanelActive={IsSidePanelActive}
				/>
			</div>

			<div className={'main-page__section'}>
				{data.length === 0 ? (
					<div className={'main-page__section--message'}>
						<p>There's no consult exist!</p>
					</div>
				) : !ActiveConsult.id ? (
					<div className={'main-page__section--message'}>
						<p>Select consult to review.</p>
					</div>
				) : (
					<>
						{0 <= ActiveConsult.images.length && (
							<div className={'main-page__section--preview'}>
								<PreviewComponent images={ActiveConsult.images} />
							</div>
						)}
						<div className={'main-page__section--rmsform'}>
							<RMSFormComponent setModified={setModified} data={ActiveConsult} />
						</div>
					</>
				)}
			</div>
		</div>
	);
};
