import { ESortBy, ESortOrder, EStatusText } from '@utils';
import ActionTypes from '../../action-types/app/app.action-types';
import { AppAction as Action } from '../../actions';

interface IState {
	activeId: number | null;
	filterStatus: string;
	sortBy: ESortBy;
	sortOrder: ESortOrder;
}

const _init: IState = {
	activeId: null,
	filterStatus: EStatusText.PENDING,
	sortBy: ESortBy.DATE,
	sortOrder: ESortOrder.ASC,
};

const reducer = (state = _init, action: Action): IState => {
	switch (action.type) {
		case ActionTypes.SET_FILTER_STATUS:
			return {
				...state,
				filterStatus: action.payload,
			};
		case ActionTypes.SET_ACTIVE_CONSULT:
			return {
				...state,
				activeId: action.payload,
			};
		case ActionTypes.RESET_APP_CONFIGS:
			return _init;
		case ActionTypes.SET_SORT_BY:
			return {
				...state,
				sortBy: action.payload,
			};
		case ActionTypes.SET_SORT_ORDER:
			return {
				...state,
				sortOrder: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
