import { useStorage } from '@hooks';
import axios from 'axios';

const instance = axios.create({
	baseURL: 'https://textmaker-be-prod2.azurewebsites.net',
	headers: {
		'Authorization': useStorage.getItem('AUTH_TOKEN')
			? `Bearer ${useStorage.getItem('AUTH_TOKEN')}`
			: '',
		'Content-Type': 'application/json',
		'accept': 'application/json',
	},
});

export default instance;
