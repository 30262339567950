import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const enhancer = () =>
	'development' === process.env.NODE_ENV
		? composeWithDevTools(applyMiddleware(thunk))
		: applyMiddleware(thunk);

export const store = createStore(rootReducer, {}, enhancer()),
	persist = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
