import { EStatusText } from '@utils';
import moment from 'moment';
import React from 'react';
import { BsCheck2All, FaSpinner, HiChevronDoubleRight, IoTrashOutline } from 'react-icons/all';
import './_style.scss';

interface IProps {
	onSwitch: (id: number) => void;
	active: boolean;
	data: IConsultList;
}

export const ListItemComponent: React.FC<IProps> = ({ onSwitch, active, data }) => {
	const appointment_date = () => {
		if (!data.appointment_date) return '--- --, ----';
		return moment(data.appointment_date).format('MMM DD, YYYY');
	};

	return (
		<div className={`list-item ${active && 'active'}`}>
			<div>
				<p className={'rider_name'}>
					{data.last_name}, {data.first_name}
				</p>

				<p className={'rider_info'}>
					<span className={'data'}>{appointment_date()}</span>
					<span className={'divider'}>{' | '}</span>
					<span className={'data'}>{data.id}</span>
					<span className={'divider'}>{' | '}</span>
					<span className={'data'}>{data.consult_id}</span>
				</p>
			</div>

			<span>
				{EStatusText.PENDING === data.status.toLowerCase() ? (
					<FaSpinner />
				) : EStatusText.REJECTED === data.status.toLowerCase() ? (
					<IoTrashOutline />
				) : (
					<BsCheck2All />
				)}
			</span>

			<button onClick={() => onSwitch(data.id)}>
				<HiChevronDoubleRight />
			</button>
		</div>
	);
};
