import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import './_style.scss';

interface IProps {
	onChange: (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null,
		opt?: IOptional,
	) => void;
	name: string;
	value: string | number;
}

export const HackInputComponent: React.FC<IProps> = ({ onChange, name, value }) => {
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);

	useEffect(() => {
		if ('appointment_time' === name) {
			return setSelectedDate(value ? moment(value, 'HH:mm').toDate() : null);
		}

		setSelectedDate(value ? moment(value, 'YYYY-MM-DD').toDate() : null);
	}, [name, value]);

	const handleDateChange = (date: MaterialUiPickersDate) => {
		const value =
			'appointment_time' !== name
				? moment(date).format('YYYY-MM-DD')
				: moment(date).format('HH:mm');

		onChange(null, { name, value });

		setSelectedDate(moment(date).toDate());
	};

	return (
		<>
			{'date_of_birth' === name ? (
				<KeyboardDatePicker
					format='MM/DD/YYYY'
					value={selectedDate}
					onChange={handleDateChange}
					required
				/>
			) : 'appointment_date' === name ? (
				<KeyboardDatePicker
					format='MMM DD, YYYY'
					value={selectedDate}
					onChange={handleDateChange}
					required
				/>
			) : (
				<KeyboardTimePicker
					ampm={false}
					value={selectedDate}
					onChange={handleDateChange}
					required
				/>
			)}
		</>
	);
};
