import { HeaderComponent, NotifierComponent } from '@components';
import { useConsultAction, useSelector, useStorage } from '@hooks';
import { AuthPageComponent, MainPageComponent } from '@pages';
import { store } from '@states';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const App: React.FC = () => {
	const {
		consult: { loading },
		user: { currentUser },
	} = useSelector((state) => state);

	const consultAction = useConsultAction();

	useEffect(() => {
		if (currentUser) {
			if (!useStorage.getItem('AUTHORIZED')) {
				useStorage.setItem('AUTHORIZED', 'YES');
				return window.location.reload();
			}

			consultAction.getConsults(store.getState().app.filterStatus);
		}
		// eslint-disable-next-line
	}, [currentUser]);

	useEffect(() => {
		const el = document.getElementById('app');
		if (el) el.style.pointerEvents = loading ? 'none' : 'auto';
		document.body.style.cursor = loading ? 'progress' : 'auto';
	}, [loading]);

	return (
		<>
			{currentUser && <HeaderComponent />}

			<NotifierComponent />

			<Switch>
				<Route
					path={'/auth'}
					render={() =>
						!currentUser ? (
							<>
								<AuthPageComponent />
							</>
						) : (
							<Redirect to={'/'} />
						)
					}
				/>
				<Route
					render={() =>
						!currentUser ? (
							<Redirect to={'/auth'} />
						) : (
							<>
								<MainPageComponent />
							</>
						)
					}
				/>
			</Switch>

			<div className={'app-info'}>
				<span>v22051501</span>
			</div>
		</>
	);
};

export default App;
