import { UserActionTypes as ActionTypes } from '../../action-types';
import { UserAction as Action } from '../../actions';

interface IState {
	currentUser: ICurrentUser | null;
	error: string | null;
	loading: boolean;
	token: IBearerToken | null;
}

const _init: IState = {
	currentUser: null,
	error: null,
	loading: false,
	token: null,
};

const reducer = (state = _init, action: Action): IState => {
	switch (action.type) {
		case ActionTypes.REQUEST_LOGIN:
			return {
				..._init,
				loading: true,
			};
		case ActionTypes.SET_CURRENT_USER:
			return {
				...state,
				currentUser: action.payload,
				error: null,
				loading: false,
			};
		case ActionTypes.REMOVE_USER:
			return _init;
		case ActionTypes.SET_TOKEN:
			return {
				...state,
				error: null,
				loading: false,
				token: action.payload,
			};
		case ActionTypes.REQUEST_ERROR:
			return {
				..._init,
				error: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
