import moment from 'moment';

/**
 * Convert the date format from the database to the RFC format
 * @param {IConsultData} v - IConsultData
 */
export const convertDateFormatToRFC = (v: IConsultData): IConsultData => ({
	...v,
	date_of_birth: moment(v.date_of_birth).isValid()
		? moment(v.date_of_birth).format('YYYY-MM-DD')
		: '',
	appointment_date: moment(v.appointment_date).isValid()
		? moment(v.appointment_date).format('YYYY-MM-DD')
		: '',
	appointment_time: moment(v.appointment_time?.replaceAll(/\s+/g, ''), 'HH:mm', '', true).isValid()
		? v.appointment_time?.replaceAll(/\s+/g, '')
		: '',
});

/**
 * Convert the date format from the database to the ISO format
 * @param {IConsultData} v - IConsultData
 */
export const convertDateFormatToISO = (v: IConsultData): IConsultData => ({
	...v,
	date_of_birth: moment(v.date_of_birth).format('YYYY-MM-DD'),
	appointment_date: moment(v.appointment_date).format('YYYY-MM-DD'),
	appointment_time: v.appointment_time || '-',
});

/**
 * Given a list of IConsultData, return a list of IConsultList
 * @param {IConsultData[]} v - IConsultData[]
 */
export const extractDataForList = (v: IConsultData[]): IConsultList[] =>
	v.map((v) => ({
		id: v.id,
		first_name: v.first_name,
		last_name: v.last_name,
		appointment_date: v.appointment_date,
		consult_id: v.consult_id,
		status: v.status,
	}));

/**
 * Replace the first occurrence of the string "http://" with "https://"
 * @param {string | null} v - The value to be converted.
 */
export const httpToHttps = (v: string | null) => (v ? v.replace(/^http:\/\//i, 'https://') : '');
