import { ListItemComponent } from '@components';
import { useAppAction, useConsultAction, useSelector } from '@hooks';
import { store } from '@states';
import { $, ESortBy, ESortOrder, EStatusText, extractDataForList } from '@utils';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineDoubleLeft } from 'react-icons/ai';
import { FaSearch, FaSpinner } from 'react-icons/fa';
import { RiRefreshLine } from 'react-icons/ri';
import './_style.scss';

interface IProps {
	setModified: React.Dispatch<React.SetStateAction<boolean>>;
	setSidePanelActive: React.Dispatch<React.SetStateAction<boolean>>;
	isModified: boolean;
	isSidePanelActive: boolean;
}

export const SidePanelComponent: React.FC<IProps> = ({
	setModified,
	setSidePanelActive,
	isModified,
	isSidePanelActive,
}) => {
	const {
		app: { activeId, filterStatus, sortBy, sortOrder },
		consult: { count, data, fetching, loading, next },
	} = useSelector((state) => state);

	const appAction = useAppAction();
	const consultAction = useConsultAction();

	const firstRenderRef = useRef(true);
	const searchInputRef = useRef<HTMLInputElement>(null);

	const [ConsultList, setConsultList] = useState<IConsultList[]>([]);
	const [IsGlobalSearch, setGlobalSearch] = useState(false);
	const [IsSearchActive, setIsSearchActive] = useState<boolean>(false);
	const [SearchValue, setSearchValue] = useState('');

	const [SortValue, setSortValue] = useState<string>(`${sortBy}-${sortOrder}`);

	useEffect(() => setConsultList(extractDataForList(data)), [data]);

	useEffect(() => {
		if (firstRenderRef.current) {
			firstRenderRef.current = false;
			return;
		}

		consultAction.getConsults(filterStatus);
		// eslint-disable-next-line
	}, [SortValue]);

	useEffect(() => {
		setIsSearchActive(!!SearchValue);
	}, [SearchValue]);

	const onFilterChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
		appAction.setFilterStatus(e.target.value.toLowerCase());
		appAction.setActiveConsult(null);

		if (!SearchValue) {
			await consultAction.getConsults(e.target.value.toLowerCase());
			setModified(false);
			return;
		}

		await consultAction.searchConsults(
			SearchValue,
			isConsultId(SearchValue),
			e.target.value.toLowerCase() as EStatusText,
			false,
		);
	};

	const onSearchKeyUp = async (e: React.KeyboardEvent<HTMLInputElement>) => {
		if ('Enter' === e.key) {
			e.currentTarget.blur();

			if (!SearchValue) return await consultAction.getConsults(store.getState().app.filterStatus);
			await searchConsults();
		}
	};

	const onSwitchHandler = (id: number) => {
		if (isModified) if (!window.confirm('Changes you made may not be saved!')) return;

		$('.main-page')[0]?.scrollTo(0, 0);

		setModified(false);
		appAction.setActiveConsult(id);
	};

	const isConsultId = (value: string) => /^[0-9]+$/.test(value);

	const searchConsults = async () => {
		await consultAction.searchConsults(
			SearchValue,
			isConsultId(SearchValue),
			undefined,
			IsGlobalSearch,
		);
	};

	return (
		<div className={`sidebar ${isSidePanelActive && 'active'}`}>
			<div className={'sidebar__header'}>
				<div className={'sidebar__header--title'}>Consult List</div>

				<div className={'sidebar__header--tools'}>
					<button
						className='refresh-btn'
						onClick={async () => {
							setSearchValue('');
							await consultAction.getConsults(store.getState().app.filterStatus);
						}}
					>
						<RiRefreshLine />
					</button>

					<div id={'records_status'}>
						<select
							name={'records_status'}
							defaultValue={filterStatus.toUpperCase()}
							onChange={onFilterChange}
							disabled={IsSearchActive && IsGlobalSearch}
						>
							{Object.keys(EStatusText).map((v) => (
								<option key={v} value={v}>
									{v}
								</option>
							))}
						</select>
					</div>

					<button className={'toggle-btn'} onClick={() => setSidePanelActive((prev) => !prev)}>
						<AiOutlineDoubleLeft />
					</button>
				</div>
			</div>

			<div className={'sidebar__search'}>
				<div className={'sidebar__search--input'}>
					<div className={'search-box'}>
						<input
							type={'search'}
							name={'search'}
							id={'search'}
							placeholder={'Search consult..'}
							value={SearchValue}
							onChange={(e) => setSearchValue(e.target.value.trimStart())}
							onKeyUp={onSearchKeyUp}
							ref={searchInputRef}
						/>

						<FaSearch
							onClick={() => {
								if (!SearchValue) return searchInputRef.current?.focus();
								searchConsults();
							}}
						/>

						<div>
							<input
								type={'checkbox'}
								name={'global-search'}
								id={'global-search'}
								checked={IsGlobalSearch}
								onChange={(e) => setGlobalSearch(e.target.checked)}
							/>

							<label htmlFor={'global-search'}>All</label>
						</div>
					</div>

					<div className={'sorting-box'}>
						<select
							defaultValue={SortValue}
							onChange={(e) => {
								e.currentTarget.blur();
								const [sort_by, sort_order] = e.target.value.split('-');
								appAction.setSortBy(sort_by as ESortBy);
								appAction.setSortOrder(sort_order as ESortOrder);
								setSortValue(e.target.value);
							}}
							disabled={IsSearchActive}
						>
							<option value={`${ESortBy.DATE}-${ESortOrder.ASC}`}>Date: Earliest</option>
							<option value={`${ESortBy.DATE}-${ESortOrder.DESC}`}>Date: Latest</option>
							<option value={`${ESortBy.ID}-${ESortOrder.ASC}`}>ID: Low to High</option>
							<option value={`${ESortBy.ID}-${ESortOrder.DESC}`}>ID: High to Low</option>
							<option value={`${ESortBy.NAME}-${ESortOrder.ASC}`}>Name: A to Z</option>
							<option value={`${ESortBy.NAME}-${ESortOrder.DESC}`}>Name: Z to A</option>
						</select>
					</div>
				</div>

				<div className={'sidebar__search--toast'}>
					{0 !== data.length && (
						<span>
							{data.length} of {count} consults
						</span>
					)}
				</div>
			</div>

			<div className={'sidebar__result'}>
				{ConsultList.length <= 0 ? (
					<p className={'info'}>{loading ? 'Just a moment...' : 'No consult found!'}</p>
				) : (
					<>
						{ConsultList.map((v) => (
							<ListItemComponent
								key={v.id}
								onSwitch={onSwitchHandler}
								active={activeId === v.id}
								data={v}
							/>
						))}

						{!!next ? (
							<button
								className={`show-more ${fetching && 'spin'}`}
								onClick={async () => {
									await consultAction.fetchMoreConsults();
								}}
							>
								{fetching ? <FaSpinner /> : 'load more'}
							</button>
						) : (
							<button className={'show-more end'}>• ⠀ • ⠀ •</button>
						)}
					</>
				)}
			</div>
		</div>
	);
};
