import _axios from '@axios';
import { useStorage } from '@hooks';
import { Dispatch } from 'redux';
import { AppActionTypes, UserActionTypes as ActionTypes } from '../../action-types';
import { AppAction, UserAction as Action } from '../../actions';

/**
 * It makes a POST request to the /token endpoint to get a JWT token.
 * @param {string} username - string
 * @param {string} password - string
 */
export const login = (username: string, password: string) => {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({ type: ActionTypes.REQUEST_LOGIN });

		try {
			const { data } = await _axios.post('/token/', { username, password });
			useStorage.setItem('AUTH_TOKEN', (data as IBearerToken).access);

			dispatch({
				type: ActionTypes.SET_TOKEN,
				payload: data as IBearerToken,
			});

			dispatch({
				type: ActionTypes.SET_CURRENT_USER,
				payload: { name: username, role: 'admin' },
			});
		} catch (e) {
			dispatch({
				type: ActionTypes.REQUEST_ERROR,
				payload: 'User authentication failed!',
			});
		}
	};
};

/**
 * It removes the user from the store and resets the app configs.
 */
export const removeUser = () => {
	return async (dispatch: Dispatch<Action | AppAction>) => {
		dispatch({ type: ActionTypes.REMOVE_USER });
		dispatch({ type: AppActionTypes.RESET_APP_CONFIGS });

		useStorage.clear();
	};
};
