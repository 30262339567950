import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { persist, store } from '@states';
import { $ } from '@utils';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import './main.scss';

ReactDOM.render(
	<React.StrictMode>
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<Provider store={store}>
				<BrowserRouter>
					<PersistGate persistor={persist}>
						<App />
					</PersistGate>
				</BrowserRouter>
			</Provider>
		</MuiPickersUtilsProvider>
	</React.StrictMode>,
	$('#app')[0],
);
