import check from '@assets/FaRegCheckCircle.svg';
import times from '@assets/FaRegTimesCircle.svg';
import infos from '@assets/ImInfo.svg';
import warns from '@assets/IoWarningOutline.svg';
import { $ } from '@utils';

export const Notify = (status_text: string, message: string, permanent?: boolean) => {
	const box = document.createElement('div');

	const clear = () => {
		setTimeout(() => {
			box.classList.remove('slide-in');

			setTimeout(() => box.remove(), 25e1);
		}, 5e2);
	};

	box.onclick = () => (permanent ? clear() : null);

	const svg = () =>
		'success' === status_text
			? check
			: 'info' === status_text
			? infos
			: 'warning' === status_text
			? warns
			: times;

	box.className = `notifier-item ${status_text}`;
	box.innerHTML = `
		<img class='notifier-item--icon' src='${svg()}' alt='status-icon'/>
		<div class='notifier-item--text'>${message}</div>
	`;

	$('.notifier')[0]?.append(box);

	/* This is a simple way to create a delay. */
	setTimeout(() => {
		box.classList.add('slide-in');

		if (permanent) return;

		setTimeout(() => {
			box.classList.remove('slide-in');

			setTimeout(() => box.remove(), 4e2);
		}, 75e2);
	}, 1e2);
};
