import { HackInputComponent } from '@components';
import React from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import './_style.scss';

interface IProps {
	onChange: (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null,
		opt?: IOptional,
	) => void;
	checked?: boolean;
	shrink?: boolean;
	name: string;
	required?: boolean;
	type: string;
	value: string | number;
}

export const InputBoxComponent: React.FC<IProps> = ({
	onChange,
	checked,
	shrink,
	name,
	required,
	type,
	value,
}) => {
	const SplitAndCapitalize = (word: string, token: string) => {
		const words = word.split(token);
		words.forEach((word, idx) => (words[idx] = word[0].toUpperCase() + word.substr(1)));
		return words.join(' ');
	};

	return (
		<div className={`input-box ${shrink && 'shrink'}`} type={type}>
			{'textfield' === type ? (
				<textarea
					onChange={onChange}
					id={name}
					name={name}
					required={required}
					rows={6}
					value={value}
				/>
			) : 'date' === type || 'time' === type ? (
				<HackInputComponent onChange={onChange} name={name} value={value} />
			) : (
				<input
					onChange={onChange}
					checked={checked}
					id={name}
					name={name}
					required={required}
					type={type}
					value={value}
				/>
			)}

			<label htmlFor={name}>{SplitAndCapitalize(name, '_')}</label>

			<div className={'ic'}>
				<AiOutlineWarning />
			</div>
		</div>
	);
};
