export * from './captalize';
export * from './converter';
export * from './notify';
export * from './selector';
export * from './sleep';
export * from './unique';
export * from './validator';

export enum EStatusText {
	PENDING = 'pending',
	VERIFIED = 'verified',
	REJECTED = 'rejected',
}

export enum ESortBy {
	DATE = 'date',
	ID = 'id',
	NAME = 'name',
}

export enum ESortOrder {
	ASC = 'asc',
	DESC = 'desc',
}

export const InitialConsult: IConsultData = {
	id: 0,
	images: [],
	consult_id: '',
	social_security_no: '',
	first_name: '',
	last_name: '',
	date_of_birth: '',
	age: 0,
	address: '',
	postal_code: '',
	phone_1: '',
	phone_2: '',
	reason_for_request: '',
	patient_trip_type: '',
	appointment_date: '',
	appointment_time: '',
	pick_up_location: '',
	drop_off_location: '',
	organization: '',
	one_trip_only: false,
	round_trip: false,
	request_mode_travel: '',
	patient_escorted: '',
	special_needs: '',
	eligible_for_travel: '',
	diagnosis: '',
	comments: '',
	status: '',
	created_at: '',
	updated_at: '',
};
