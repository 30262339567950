import React, { useEffect, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import ImagePanZoom from 'react-image-pan-zoom-rotate';
import './_style.scss';

interface IProps {
	images: string[];
}

export const PreviewComponent: React.FC<IProps> = ({ images }) => {
	const [CurrentImage, setCurrentImage] = useState('');
	const [IsPanZoomActive, setPanZoomActive] = useState(false);
	const [SlideIndex, setSlideIndex] = useState(0);

	useEffect(() => setSlideIndex(0), [images]);

	useEffect(() => {
		const el = document.querySelector('.image-modal > div > div');
		const rs = el?.lastChild as HTMLButtonElement;
		const cb = document.createElement('div');

		el?.classList.add('action');
		el?.prepend(cb);

		cb.style.cssText = `
			text-align: center; cursor: pointer; height: 40px; width: 40px; border-bottom: 1px solid rgb(204, 204, 204)
		`;
		cb.innerHTML = `
			<svg stroke='#4C68C1' fill='#4C68C1' stroke-width='0' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' style='height: 100%; width: 100%; padding: 9px;'>
				<path d='M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z'></path>
			</svg>
		`;
		cb.onclick = () => {
			setPanZoomActive(false);
			rs.click();
		};

		document.onkeyup = (e) => {
			if ('Escape' === e.code) {
				setPanZoomActive(false);
				rs.click();
			}
		};

		return () => {
			document.onkeyup = null;
			setSlideIndex(0);
		};
	}, []);

	useEffect(() => {
		const slides: HTMLElement[] = [].slice.call(document.querySelectorAll('.slides'));
		const indicators: HTMLElement[] = [].slice.call(document.querySelectorAll('.dot'));

		setSlideIndex((prev) => (prev < 0 ? slides.length - 1 : prev >= slides.length ? 0 : prev));
		if (!indicators[SlideIndex]) return;

		slides.forEach((slide) => (slide.style.display = 'none'));
		slides[SlideIndex].style.display = 'block';

		indicators.forEach((indicator) => indicator.classList.remove('active'));
		indicators[SlideIndex].classList.add('active');

		setCurrentImage(`data:image/png;base64,${images[SlideIndex]}`);
	}, [images, SlideIndex]);

	const openImageInNewWindow = () => {
		const image = new Image();
		image.src = CurrentImage;
		image.style.height = '100vh';
		const w = window.open('', '_blank', 'popup');
		w?.document.write(`
			<div style='background: black; position: absolute; inset: 0 0 0 0; display: grid; place-items: center'>${image.outerHTML}</div>
		`);
	};

	return (
		<div className={'preview'}>
			<div className={'preview__slider'}>
				{images.map((image, idx) => (
					<div key={idx} className={'preview__slider--slide slides fade'}>
						<img
							src={`data:image/png;base64,${image}`}
							alt={'single consult sheet'}
							className={'image'}
							onClick={() => setPanZoomActive(true)}
						/>

						<p className={'count'}>{`${idx + 1}/${images.length}`}</p>
					</div>
				))}

				<button
					className={'preview__slider--navigator prev'}
					onClick={() => setSlideIndex((prev) => prev - 1)}
					tabIndex={-1}
				>
					<FaChevronLeft />
				</button>

				<button
					className={'preview__slider--navigator next'}
					onClick={() => setSlideIndex((prev) => prev + 1)}
					tabIndex={-1}
				>
					<FaChevronLeft />
				</button>

				<div className={'preview__slider--indicator'}>
					{images.map((_, idx) => (
						<span key={idx} className={'dot'} onClick={() => setSlideIndex(idx)} />
					))}
				</div>
			</div>

			<div className={`image-modal ${IsPanZoomActive && 'active'}`}>
				<ImagePanZoom image={CurrentImage} alt={'single consult sheet'} />
				<button
					className={'image-modal--navigator prev'}
					onClick={() => setSlideIndex((prev) => prev - 1)}
					tabIndex={-1}
				>
					<FaChevronLeft />
				</button>

				<button
					className={'image-modal--navigator next'}
					onClick={() => setSlideIndex((prev) => prev + 1)}
					tabIndex={-1}
				>
					<FaChevronLeft />
				</button>

				<button
					className={'image-modal--open-in-new-window'}
					onClick={openImageInNewWindow}
					tabIndex={-1}
				>
					Open in new window
				</button>
			</div>
		</div>
	);
};
