import validator from 'validator';

export const isValidPostalCode = (value: string) => validator.isPostalCode(value, 'US');

export const isValidConsult_Id = (value: string) => /^[0-9]{7}$/.test(value);

export const isValidSocialSecurityNo = (value: string) => /^[0-9]{4}$/.test(value);

export const isValidAge = (value: number) => 112 > value && /^[0-9]{1,3}$/.test(value.toString());

export const isValidPhone = (value: string) => validator.isMobilePhone(value, 'en-US');
