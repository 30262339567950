import logo from '@assets/logo.png';
import { useSelector, useUserAction } from '@hooks';
import { $ } from '@utils';
import React, { useEffect, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import './_style.scss';

export const AuthPageComponent: React.FC = () => {
	const { error, loading } = useSelector((state) => state.user);

	const userAction = useUserAction();

	const [FormInput, setFormInput] = useState({ username: '', password: '' });

	useEffect(() => {
		if (error) ($('#username')[0] as HTMLInputElement)?.focus();
	}, [error]);

	const onChangeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormInput((input) => ({ ...input, [e.target.name]: e.target.value }));
		error && (await userAction.removeUser());
	};

	const onSubmitHandler = async (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault();
		await userAction.login(FormInput.username, FormInput.password);
	};

	return (
		<div className={'auth-page'}>
			<div className={'auth-page-container'}>
				<div className={'auth-page__header'}>
					<div className={'auth-page__header--logo'}>
						<img src={logo} alt={'site logo'} />
					</div>

					<div className={'auth-page__header--text'}>
						<p>Login to continue....</p>
					</div>
				</div>

				<form className={'auth-page__form'} onSubmit={onSubmitHandler}>
					<div className={'auth-page__form--main'}>
						<span className={`message ${error && 'show'}`}>{error}</span>

						<div className={'input-container'}>
							<div className={'input-container__label'}>
								<p className={'input-container__label--text'}>Username</p>
							</div>

							<input
								type={'username'}
								name={'username'}
								id={'username'}
								className={error ? 'error' : ''}
								value={FormInput.username}
								onChange={onChangeHandler}
								autoComplete={'on'}
								required
							/>
						</div>

						<div className={'input-container'}>
							<div className={'input-container__label'}>
								<p className={'input-container__label--text'}>Password</p>
							</div>

							<input
								type={'password'}
								name={'password'}
								id={'password'}
								className={error ? 'error' : ''}
								value={FormInput.password}
								onChange={onChangeHandler}
								autoComplete={'on'}
								required
							/>
						</div>

						<button type={'submit'} disabled={loading}>
							{loading ? <FaSpinner /> : 'LOGIN'}
						</button>
					</div>
				</form>

				<p id='crd'>&copy; 2021 - {new Date().getFullYear()} Custoapps</p>
			</div>
		</div>
	);
};
